<template>
	<div class="ticketWrap">
		<template v-if="pFlag">
			<div ref="print">
				<table border align="center" class="table">
					<tr class="titleTr">
						<td colspan="4">{{ cst.zh_name }}<br />准 考 证</td>
					</tr>
					<tr>
						<td width="150px">姓　　名</td>
            <td width="380px">{{ list.user_name }}</td>
          <td rowspan="6" style="position: relative;width: 175px;height:237px">
            <p style="position: absolute;left: 10px;right: 10px;top:10px;bottom: 10px;">
                <img
                    style="width: 100%;height: 100%"
                    v-if="list.face_img"
                    :src="list.face_img"
                    alt=""
                >
            </p>
          </td>
					</tr>
					<tr>
						<td>身份证号</td>
						<td>{{ list.cert_num }}</td>
					</tr>
					<tr>
						<td>准考证号</td>
						<td>{{ list.ticket }}</td>
					</tr>
          <tr>
              <td>小米ID</td>
              <td>{{ list.job_num }}</td>
          </tr>
					<tr>
						<td>考点名称</td>
						<td>{{ cst.address_name }}</td>
					</tr>
					<tr>
						<td>考点地址</td>
						<td>{{ cst.address }}</td>
					</tr>
				</table>
				<table border align="center" class="table">
					<tr class="titleTr">
						<td colspan="4">考试安排</td>
					</tr>
					<tr>
						<td width="180px">考试科目</td>
						<td width="250px">考试时间</td>
						<td width="150px">考场号</td>
						<td width="150px">座位号</td>
					</tr>
					<tr>
						<td>
							{{ cst.name }}
						</td>
						<td>
							<template v-if="cst.start_time">
								{{
									cst.start_time
										| formatimes('yyyy-MM-dd hh:mm')
								}}-{{ cst.end_time | formatimes('hh:mm') }}
							</template>
						</td>
						<td>{{ examroom.name }}</td>
						<td>{{cst.subject_id==2?'—':list.seatno==0?'—':list.seatno }}</td>
					</tr>
					<tr>
						<td colspan="4">
							<p class="titleTr mb10">注意事项</p>
							<div class="tips">
								<p>
									一、应试人员应当尊重监考人员，自觉接受监考人员的监督和检查，服从监考人员的管理，不得以任何理由妨碍监考人员履行职责，不得扰乱考场秩序。</p>
								<p>
									二、应试人员应提前三十分钟进入考场，开考十五分钟后，不得进入考场。考试结束前三十分钟内，应试人员经监考员同意后可以交卷离开考场。</p>
								<p>
									三、应试人员进入考场后，应当对号入座，将准考证、身份证放在桌子右上角，供监考员查验。</p>
								<p>
									四、应试人员不得携带书籍笔记本、纸张、报刊、电子用品、存储设备及通信工具等物品进入考场。</p>
								<p>
									五、应试人员应当遵守下列考试纪律：<br>
									（一）不得在考场内喧哗、走动等影响他人考试或者交卷后在考场附近喧哗等影响考试秩序；<br>
									（二）不得在考场内交头接耳、左顾右盼或者以互打手势等方式传递信息；<br>
									（三）不得窥视、抄袭他人答卷或者同意他人抄袭；<br>
									（四）考生交卷后不得在考场内逗留，不得将考场配发的材料带出考场，不得抄写试题或者以任何形式将试题信息进行记录、存储、传输出考场；<br>
									（五）不得有其他违反考试纪律的行为。
								</p>
								<p>
									六、考试结束指令发出后，应试人员应当立即停止答题，经监考员核验无误后，方可离开考场。
								</p>
							</div>
						</td>
					</tr>
				</table>
			</div>
			<div class="btnBottom noprint">
				<el-button
					type="primary"
					round
					@click="getPrint"
					>打印</el-button
				>
			</div>
		</template>
    <template v-else>
      <div class="noData">
        准考证生成失败，请联系管理员解决
      </div>
    </template>
	</div>
</template>
<script>
import { xiaomicertificate } from '../assets/api';
export default {
	data() {
		return {
			list: {},
			cst: {},
			examroom: {},
			pFlag: true,
		};
	},
	mounted() {
		this.getInfo();
	},
	methods: {
		getInfo() {
			const params = {
				course_paper_id: this.$route.query.course_paper_id,
			};
			xiaomicertificate(params).then((res) => {
				if (res.code == 0) {
					if (res.data.list && res.data.list[0].ticket) {
            this.pFlag=true
						this.list = res.data.list[0];
						this.cst = res.data.cst;
						this.examroom = res.data.examroom;
					} else {
						this.pFlag = false;
						this.$message.error('准考证生成失败，请联系管理员解决');
					}
				}
			});
		},
		getPrint() {
			// //加ref读取标签，no-print则不参与打印，打印调用如下：
			// this.$print(this.$refs.print);
			// //因为每次打印都会多一个img（将echarts的canvas转为img），我的print.js里头已经配置给这个img里头加的classname为isNeedRemove，所以每次打印完需去除，我只有一张echarts、去除的数量根据业务需求定。
			// this.$nextTick(() => {
			// 	let arr = document.getElementsByClassName('isNeedRemove');
			// 	if (arr.length) arr[0].remove();
			// });
      window.print();
		},
	},
};
</script>

<style scoped lang="scss">
/* 去除页眉页脚 */
@page {
	size: auto A4 landscape;
	margin: 30px auto;
}
@media print{
  @-moz-document url-prefix(){
      .table {
          tr {
              border:2px solid #000!important;
              td {
                  border:2px solid #000!important;
              }
              th{
                  border:2px solid #000!important;
              }
          }
      }
  }
  .noprint{
            display: none!important;
        }
}


.ticketWrap {
	padding: 30px 0;
}

.table {
	width: 780px;
	text-align: center;
	font-size: 14px;
	&:last-child {
		margin-top: -1px;
	}
	tr {
		width: 100%;
		text-align: center;

		td {
			padding: 10px;
			min-height: 42px;
		}
	}
	.titleTr {
		font-size: 16px;
		font-weight: bold;
		&:first-child {
			font-size: 18px;
		}
	}
	.tips {
		text-indent: 28px;
		text-align: left;
		padding-bottom: 10px;
		line-height: 24px;
	}
}
.btnBottom {
	text-align: center;
	padding-top: 20px;
}
.noData{
  margin:50px auto;
  text-align: center;
  font-size: 20px;
}
</style>
